import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { INLINES } from '@contentful/rich-text-types';
import { PopupModal } from 'react-calendly';
import CaseSingleStyled from '@/components/styled/CaseSingleStyled';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import { CaseBlockProp, CaseProp, CaseProps } from '@/types';
import Helpers from '@/utils/helpers';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

const override = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        href={data.uri}
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        {children}
      </a>
    ),
  },
};

const Case: React.FC<CaseProps> = ({ data }) => {
  const [nextCase, setNextCase] = useState(null);
  const [author, setAuthor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const windowWidth = Helpers.getWindowInnerWidth();

  useEffect(() => {
    const nextOrder: number = data.contentfulCase.order + 1;
    const next: CaseProp = data.allContentfulCase.nodes.find(
      (_case) => _case.order === nextOrder,
    );

    if (data.allContentfulCase.nodes.length && next === undefined) {
      const first = data.allContentfulCase.nodes[0];
      setNextCase(first);
    } else {
      setNextCase(next);
    }

    const writer = data.contentfulCase?.author ?? null;
    if (writer) {
      setAuthor(data.contentfulCase?.author);
    }
  }, []);

  const renderCaseBlocks = (): JSX.Element[] =>
    data.contentfulCase.blocks.map((block: CaseBlockProp) => {
      let contentHeadline = block.headline?.toLowerCase() ?? null;

      if (
        block.headline &&
        (contentHeadline.includes(`the solution`) ||
          contentHeadline.includes(`the challenge`))
      ) {
        let headlineArray = [];
        headlineArray = contentHeadline.split(/(\s+)/);
        headlineArray = headlineArray.map(
          (strng) => strng.charAt(0).toUpperCase() + strng.slice(1),
        );
        contentHeadline = headlineArray.join(``);
      } else {
        contentHeadline =
          block.headline !== null
            ? Helpers.capitalizeFirstLetter(block.headline)
            : ``;
      }

      return (
        <Reveal
          className={`row case-block ${
            block.imageFirstOnDesktop ? `image-first-desktop` : ``
          } ${block.imageFirstOnMobile ? `image-first-mobile` : ``}`}
          key={block.id}
        >
          <div
            className={`case-block-content ${
              !block.images
                ? `col-3 col-t-2 col-m-2 col-offset-1 col-offset-t-0 col-offset-m-0`
                : `col-2 col-t-2 col-m-2`
            }`}
          >
            {block.headline && <h2>{contentHeadline}</h2>}
            <ContentfulRawToComponent
              raw={block.content.raw}
              options={override}
            />
          </div>
          {block.images && block.images.length > 0 && (
            <div className="col-2 col-t-2 col-m-2 case-block-image">
              <div className="row">
                {block.images.map((image) => (
                  <div
                    key={image.id}
                    className={`
                    col-${4 / block.images.length}
                    col-t-${2 / block.images.length}
                    col-m-${2 / block.images.length}
                    `}
                  >
                    <div
                      className={`image-container case-block-image-container ${
                        block.images.length > 1 ? `has-other-images` : ``
                      }`}
                    >
                      <GatsbyImage
                        className="i-amphtml-fill-content i-amphtml-replaced-content"
                        image={getImage(image.image.gatsbyImageData)}
                        alt={image.imageAlt || ``}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Reveal>
      );
    });

  const isMobile = windowWidth <= Helpers.window.breakpoint.mobile;

  const showFeaturedImage = (): ImageDataLike =>
    isMobile
      ? data.contentfulCase?.featuredImageMobile?.gatsbyImageData
      : data.contentfulCase?.featuredImage?.gatsbyImageData;

  const bookButtonText = (): string => `Book a 30 min intro`;

  return (
    <CaseSingleStyled>
      {usePageMeta(data.contentfulCase?.meta)}
      <div id="content-case" className="content content-case">
        <div className="container">
          <div className="page-title mini-title">
            <Reveal element="h1">Case {data.contentfulCase.projectName}</Reveal>
          </div>

          <div id="main-content" className="main-content">
            <Reveal element="h1">{data.contentfulCase.headline}</Reveal>
            <Reveal className="image-container featured-image-container">
              <GatsbyImage
                image={getImage(showFeaturedImage())}
                alt={data.contentfulCase.featuredImageAlt || ``}
              />
            </Reveal>
            <Reveal className="content-container">
              <ContentfulRawToComponent raw={data.contentfulCase.content.raw} />
            </Reveal>
          </div>
          <div className="case-blocks">{renderCaseBlocks()}</div>
          <div className="case-navigation">
            <div className="row">
              <Reveal
                className={`${
                  author ? `col-1 col-t-1 col-m-1` : `col-2 col-t-2 col-m-1`
                } link-back`}
              >
                <Link className="link back" to="/work/">
                  All cases
                </Link>
              </Reveal>

              {author && (
                <div id="author" className="col-2 col-t-2 col-m-2">
                  <Reveal className="author-container">
                    <div className="description">Let&apos;s connect.</div>

                    <div className="author-details">
                      <div className="image-container">
                        <GatsbyImage
                          image={getImage(
                            data.contentfulCase.author.photo.gatsbyImageData,
                          )}
                          alt={`${data.contentfulCase.author.firstName} ${data.contentfulCase.author.lastName} Photo`}
                        />
                      </div>

                      <div className="about-author">
                        <div className="author-info">
                          <p className="name">
                            {data.contentfulCase.author.firstName}
                            {` `}
                            {data.contentfulCase.author.lastName}
                          </p>
                          <p className="position">
                            {data.contentfulCase.author.designation}
                          </p>

                          <p className="phone">
                            {data.contentfulCase.author.phoneNumber}
                          </p>

                          <a
                            className="email"
                            href={`mailto:${data.contentfulCase.author.emailAddress}`}
                          >
                            {data.contentfulCase.author.emailAddress}
                          </a>
                        </div>
                      </div>

                      {data.contentfulCase.author.calendlyUrl && (
                        <>
                          <div className="book-demo">
                            <a
                              id="calendlyButton"
                              href="#main-content"
                              className="btn calendly work-case"
                              aria-label={`Book a meeting with ${data.contentfulCase.author.firstName} ${data.contentfulCase.author.lastName}`}
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <span>{bookButtonText()}</span>
                            </a>
                          </div>

                          <PopupModal
                            url={`${data.contentfulCase.author.calendlyUrl}?hide_gdpr_banner=1`}
                            onModalClose={() => setIsOpen(false)}
                            open={isOpen}
                            rootElement={document.getElementById(
                              `content-case`,
                            )}
                          />
                        </>
                      )}
                    </div>
                  </Reveal>
                </div>
              )}

              {nextCase && (
                <div
                  id="next-case"
                  className={
                    author ? `col-1 col-t-1 col-m-1` : `col-2 col-t-2 col-m-1`
                  }
                >
                  <Reveal className="next-case-container card">
                    <Link
                      to={`/work/${nextCase.slug}`}
                      aria-label={`View next case ${nextCase.projectName}`}
                    >
                      <div className="image-container next-case-image-container">
                        <GatsbyImage
                          image={getImage(nextCase.featuredImageMobile)}
                          alt={`Next case ${nextCase.projectName}`}
                        />
                      </div>
                    </Link>
                    <Link
                      className="link"
                      to={`/work/${nextCase.slug}`}
                      aria-label={`View next case ${nextCase.projectName}`}
                    >
                      Case {nextCase.projectName}
                    </Link>
                  </Reveal>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CaseSingleStyled>
  );
};

export const query = graphql`
  query WorkQuery($id: String) {
    allContentfulCase(sort: { fields: order }) {
      nodes {
        projectName
        slug
        order
        featuredImageAlt
        featuredImageMobile {
          gatsbyImageData
        }
      }
    }
    contentfulCase(id: { eq: $id }) {
      projectName
      slug
      headline
      order
      featuredImageAlt
      featuredImage {
        gatsbyImageData
      }
      featuredImageMobile {
        gatsbyImageData
      }
      content {
        raw
      }
      blocks {
        id
        headline
        title
        imageFirstOnDesktop
        imageFirstOnMobile
        images {
          id
          image {
            gatsbyImageData
          }
          imageAlt
        }
        content {
          raw
        }
        images {
          image {
            gatsbyImageData
          }
          imageAlt
        }
      }
      author {
        photo {
          gatsbyImageData
        }
        firstName
        lastName
        designation
        phoneNumber
        emailAddress
        calendlyUrl
      }
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export default Case;
